import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import { email } from '@config';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: calc(var(--nav-height) + 50px) 0 0; // Increased padding-top

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hi, my name is</h1>;
  const two = <h2 className="big-heading">Yulong Lin.</h2>;
  // const three = <h3 className="big-heading">I build software.</h3>;
  const three = <h3 className="big-heading">Let's make AI safe.</h3>;
  const four = (
    <>
      <p>
        {/* I’m a software engineer specializing in building (and occasionally designing) exceptional
        digital experiences. Currently, I’m focused on building accessible, human-centered products
        at{' '}
        <a href="https://upstatement.com/" target="_blank" rel="noreferrer">
          Upstatement
        </a>
        . */}
        I'm a Machine Learning Engineer in the ByteDance Seed team,
        and am transitioning to AI safety.
        While I work on advancing and deploying LLMs, I believe AI systems could match or exceed human capabilities in
        virtually all computer-based work within the next decade. I thus hope to ensure they remain
        robustly beneficial and under meaningful human control. Previously, I spent time at{' '}
        <a href="https://cam.ac.uk/" target="_blank" rel="noreferrer">
          Cambridge
        </a>
        ,{' '}
        <a href="https://cohere.ai/" target="_blank" rel="noreferrer">
          Cohere
        </a>
        ,{' '}
        <a href="https://aws.amazon.com/" target="_blank" rel="noreferrer">
          AWS
        </a>{' '}
        and{' '}
        <a href="https://www.berkeley.edu/" target="_blank" rel="noreferrer">
          UC Berkeley
        </a>
        . I'm willing to relocate for the right role, and have work authorisation in the UK and
        Singapore. As a Singaporean citizen, I also qualify for the H-1B1 visa to work in the US
        (95-100% approval rate, no lottery required).
      </p>
    </>
  );
  const five = (
    // <a
    //   className="email-link"
    //   href="https://www.newline.co/courses/build-a-spotify-connected-app"
    //   target="_blank"
    //   rel="noreferrer">
    //   Check out my course!
    // </a>
    <a className="email-link" href={`mailto:${email}`}>
      Get in Touch
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
